#detail {
  background-color: rgba(218, 214, 214, 0.62);
}

.bg-light-blue{
  background-color: rgb(248 250 255) !important;
}

[small-icon] {
  transform: scale(0.625);
  color: #3F51B5;
}
[pointer] {
    cursor: pointer;
}
.gap-20 {
  gap: 20px;
}

.gap-10 {
  gap: 10px;
}

.gap-5 {
  gap: 5px;
}

.gap-64 {
  column-gap: 64px;
}

.size-64 {
  min-height: 80px;
}

.size-32 {
  min-height: 2rem !important;
}

.extended-fab-button {
  position: fixed !important;
  bottom: 2rem !important;
  right: 2rem !important;
  z-index: 10000 !important;
  width: unset !important;
  padding: 0 26px !important;
  border-radius: 29px !important;
}
.extended-fab-button2 {
  position: fixed !important;
  bottom: 10rem !important;
  right: 2rem !important;
  z-index: 10000 !important;
  width: unset !important;
  padding: 0 26px !important;
  border-radius: 29px !important;
}
.language-fab-button {
  position: fixed !important;
  bottom: 2rem !important;
  left: 8rem !important;
  z-index: 10001 !important;
  width: unset !important;
  padding: 0 26px !important;
  border-radius: 29px !important;
}
.extended-fab-button-dialog {
  position: absolute !important;
  bottom: 13vh !important;
  right: 21vw !important;
  z-index: 10000 !important;
  width: unset !important;
  padding: 0 26px !important;
  border-radius: 29px !important;
}
.language-fab-button-dialog {
  position: absolute !important;
  bottom: 13vh !important;
  left: 21vw !important;
  z-index: 10001 !important;
  width: unset !important;
  padding: 0 26px !important;
  border-radius: 29px !important;
}
.extended-fab-button__text {
  font-weight: 300 !important;
  letter-spacing: .1em !important;
  text-transform: uppercase !important;
}
.language-fab-button__text {
  font-weight: 300 !important;
  letter-spacing: .1em !important;
  text-transform: uppercase !important;
}
.cf-cursor-pointer
{
    cursor:pointer;
}

.cf-widht-85vw
{
    width:85vw;
}

.cf-height-max-content
{
    height:max-content;
}

.scale2x {
  transform: scale(2);
}

.scale1dot3x {
  transform: scale(1.3);
}

.icon-background-blue {
  color: #0f46ff !important;
}

.text-wrap-2-lines {
  line-height: 1.5em;
  height: 3em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.text-center {
  text-align: center;
}

.rounded-field
{
    border-radius:5px;
}

.pui-detail-dialog
{
    width:70vw;
    height:75vh;
}

/*sized-boxes*/
.cf-box-30 {
  height: 30px;
}

.cf-box-60 {
  height: 60px;
}

.cf-box-10 {
  height: 10px;
}

.cf-box-5 {
  height: 5px;
}

.cf-box-currency {
  width: 4rem;
}

/*paddings*/

.cf-padding-lr-20
{
    padding-left: 20px;
    padding-right: 20px;
}

.cf-padding-lr-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.cf-padding-lr-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.cf-padding-lr-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.cf-padding-lr-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.padding-12-tb {
  margin-top: 12px;
  margin-bottom: 12px;
}

.padding-0{
  padding: 0 !important;
}

.input-padding-0 {
  margin-bottom: -1.25em;
}

.padding-4 {
  padding: 4px;
}

.padding-8 {
  padding: 8px;
}

.padding-16 {
  padding: 16px;
}

.padding-b-20 {
  padding-bottom: 20px;
}

/* OTHER */

.menu-expansion-panel-bg {
  background-color: #f4f9ff !important;
}

.menu-expansion-panel {
  background-color: #f4f9ff;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-bottom :1px solid gray;
  width: 100%;
  
}

.border-top-gray {
  border-top: 1px solid gray;
}


.side-menu-content {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  
}