/* You can add global styles to this file, and also import other style files */

/*  @import "@angular/material/prebuilt-themes/indigo-pink.css";  */
/* @import "@angular/material/prebuilt-themes/pink-bluegrey.css"; */
/* @import "@angular/material/prebuilt-themes/purple-green.css"; */

@import "cafe-decor.css";
@import "cafe-flex.css";
@import "fonts.css";

* {
box-sizing:border-box;
}

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.col-md-150-card {
  flex: 0 0 10.5%;
  max-width: 10.5%;
  margin: 5px;
}

.col-md-385 {
  flex: 0 0 30.875%;
  max-width: 33.875%;
}

.col-md-585 {
  flex: 0 0 46.875%;
  max-width: 46.875%;
}


.col-md-285 {
  flex: 0 0 20.875%;
  max-width: 20.875%;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.card-margin {
  margin: 5px;
}

.customer-card {
  height: 75px;
  text-align: center;
  align-content: center;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  display: flex;
  flex-direction: column;
}
.content {
  flex: 1 0 auto;
}
.test:hover {
  transform: scale(1.5);
}


/* html body {
  /* If you spend long hours looking at your pc monitor screen daily, you might want to protect your eyes
  by changing the background colour of your pc desktop to light green instead of the default white colour. */
  /*background-color: rgb(219, 238, 221);} */

.full-width {
  width: 100%;
}

.fill-remaining-space-small {
  flex: 0.02 0.02 auto;
}

.fill-remaining-space-medium {
  flex: 0.2 0.2 auto;
}

.fill-remaining-space {
  flex: 1 1 auto;
}

.mat-icon-0750-size {
  transform: scale(0.75);
}

.mat-icon-1125-size {
  transform: scale(1.125);
}

.mat-icon-150-size {
  transform: scale(1.4);
}

.mat-icon-200-size {
  transform: scale(2);
}

.mat-icon-300-size {
  transform: scale(3);
}

.cursor-pointer {
  cursor: pointer;
}
/*****************Mat table column design  START **************************/
.datatable-container {
  overflow: hidden;
}

.th-td-center {
  text-align: center;
  justify-content: center;
}

.mat-column-id {
  /*Hide all the id columns in all the mat tables, done for master data.*/
  display: none;
}

.font-14 {
  font-size: 0.875rem;
}

.font-16 {
  font-size: 1rem;
}

.menu-padding {
  padding: 0 16px !important;
}

.mat-expansion-panel-header {
  height: inherit !important;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1920px;
  }
}

.warn {
  color: #C44900;
  font-family: Roboto;
  font-weight: 500;
}

.blue-button{
  border-radius: 0.25rem;
  padding: 0.5rem 0.25rem 0.5rem 0.5rem;
  border: none;
  background-color: #3f51b5; 
  color: white; 
  margin-top:-0.5rem;
}

.blue-snackbar {
  background: #2196F3;
}

.green-snackbar {
  background: #1DE9B6;
}

.red-snackbar {
  background: #B00020;
}

.header_green {
  background-color: hsl(120, 95%, 95%);
}

.header_red {
  background-color: hsl(0, 95%, 95%);
}

.header_grey {
  background-color: darkgray;
}

/* Styles for the active tab label */
app-md-menu .mat-tab-label.mat-tab-label-active {
  justify-content: center;
  background-color: #1b6ec2;
  color: white;
  font-size: 18px;
  max-height: 40px;
}
/* Styles for tab labels */
app-md-menu .mat-tab-label {
  padding: 0px 10px;
  justify-content: flex-start;
  background-color: none;
  color: black;
  max-height: 30px;
}
/* Styles for the ink bar */
/*app-md-menu .mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background: #3F51B5;
}*/

.mat-expansion-panel-header-description, .mat-expansion-indicator::after {
  color: white;
}

app-media-plan-menu .mat-card-header-text {
  margin: 0 0;
}

.mat-menu-panel {
  min-width: 40px !important;
  max-width: none !important;
}

/*Actions column in Media Plan managament views to be always full size visible*/
.actions-width {
  min-width: 130px;
}

.mandatory {
  color: darkred;
}

app-home-menu .mat-card, app-report-menu .mat-card, app-login .mat-card {
  border: 1px solid darkgrey;
  border-radius: 10px;
}

.mat-select-panel {
  /*  overflow: visible !important;*/
  max-height: 60vh !important;
}

app-planning-summary .mat-expansion-panel-body app-planning-summary-calendar .mat-expansion-panel-body {
  padding: 0 0 0 0 !important;
}

/*app-pui-menu .mat-expansion-panel-header {
  background-color: #1b6ec2;
}*/

.mat-drawer-container {
  background-color: #ffffff !important;
}

.arrow {
  outline:none !important;
  transform: scale(1.6);
}
button:focus {
  outline: 0;
}

.mat-tooltip-multiline {
  white-space: pre-line;
  font-size:larger;
}

.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 1rem;
}

.mat-card-header .mat-card-title {
  margin-bottom: 0px !important;
}

.mat-sidenav, 
.mat-sidenav.md-locked-open, 
.mat-sidenav.md-closed.md-locked-open-add-active {
    min-width: 176px !important;
    width: 11rem !important;
    max-width: 208px !important;
    align-items: center;
} 

.mat-sidenav{
  position: fixed !important;
  top: 0 !important;
}

.mat-list-base .mat-list-item .mat-list-item-content, .mat-list-base .mat-list-option .mat-list-item-content {
  justify-content: center;
}

.mat-content {
  justify-content: center;
}

.mat-expansion-panel-body {
  padding: initial !important;
}

.mat-list-base .mat-list-item.mat-list-item-with-avatar {
  min-height: 80px;
  height: auto !important;

}

.mat-list-base  {
  padding-top: 0 !important;
}

.mat-list-item__primary-text {
  width: 100%;
}

.mat-list-item {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.mat-text-field--outlined {
  background-color: white;
}

.mat-card-table:nth-of-type(2n) {
  background-color: rgb(250, 250, 250) !important;
}


.mat-card-table:nth-of-type(1n+2) {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.mat-card-table:nth-of-type(1) {
  padding-top: 0;
  padding-bottom: 0.5rem;
}



app-nav-menu {
  position: sticky;
  position: -webkit-sticky; /* For macOS/iOS Safari */
  top: 0; /* Sets the sticky toolbar to be on top */
  z-index: 1000; /* Ensure that your app's content doesn't overlap the toolbar */
  min-height:30px;
}

.sticky-bottom {
  position: sticky;
  position: -webkit-sticky; /* For macOS/iOS Safari */
  bottom: 0; /* Sets the sticky toolbar to be on top */
  /*z-index: 1000;*/ /* Ensure that your app's content doesn't overlap the toolbar */
  height: auto;
  align-self: center;
  justify-content: center;
}

.cf-sizebox-w-60{
    width: 60px;
}

th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  padding: 8px;
}